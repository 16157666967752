.Toastify--animate {
    animation-fill-mode: both;
    animation-duration: 0.7s;
}

.Toastify--animate-icon {
    animation-fill-mode: both;
    animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
    .Toastify__toast {
        margin-bottom: 0;
        border-radius: 0;
    }
}
@keyframes Toastify__trackProgress {
    0% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(0);
    }
}

.Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards;
}

@keyframes Toastify__bounceOut {
    to {
        opacity: 0;
    }
}
@keyframes Toastify__bounceIn {
    to {
        opacity: 1;
    }
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
    animation-name: Toastify__bounceIn;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
    animation-name: Toastify__bounceOut;
}

.react-confirm-alert-overlay {
    display: flex;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    width: 100%;
    height: 100vh;
    background: rgb(0, 0, 0, 0.4) !important;
    backdrop-filter: blur(5px);
}

