
.dots_custom {
    display: inline-block;
    vertical-align: middle;
    margin: auto 0;
    padding: 0;
}

.dots_custom li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    margin: 0 3px;
    padding: 0;
}

.dots_custom li button {
    border: none;
    background: #d1d1d1;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 4px;
    width: 4px;
    border-radius: 100%;
    padding: 0;
}

.dots_custom li.slick-active button {
    background-color: blue;
    border-radius:100px;
    width:20px;
}