
* {
  font-family: "SFProText","Pretendard-Regular" !important;
}


img {
  vertical-align: middle;
}

.gray_1A1E21_box{
  padding: 20px 20px;
  border-radius: 20px;
  background-color: #1A1E21;
}

.gray_323A40_box{
  padding: 10px 12px;
  border-radius: 12px;
  background-color: #323A40;
}

.white_in_box{
  padding:7px 14px;
  align-items: center;
  border-radius: 100px;
  color:#1A1E21;
  background-color: #FFFFFF;
  border: 1px solid #E4E8EC;
}
.white_out_box{
  border-radius: 100px;
  border: 1px solid #FFFFFF;
}
.white_line {
  height: 1px;
  align-self: stretch;
  flex-grow: 0;
  background-color: #FFFFFF;
}

.gray_line {
  height: 1px;
  align-self: stretch;
  flex-grow: 0;
  background-color: #DADADA;
}

.blue_in_box{
  padding:7px 14px;
  align-items: center;
  border-radius: 100px;
  color:#1A1E21;
  background-color: #317FF4;
}
.yellow_in_box{
  padding:8px 12px;
  align-items: center;
  border-radius: 100px;
  color:#1A1E21;
  font-size: 12px;
  font-weight: 500;
  background-color: #FFDD00;
  text-align: center;
}
.black_in_box{
  padding:6px 12px;
  align-items: center;
  border-radius: 100px;
  color:#FFFFFF;
  background-color: #323A40;
}
.gray_in_box{
  padding:6px 12px;
  align-items: center;
  border-radius: 100px;
  color:#FFFFFF;
  background-color: #828282;
}
.gray_in_box2{
  padding:6px 12px;
  align-items: center;
  border-radius: 100px;
  color:#000000;
  background-color: #F8F9FA;
}
.gray_in_box3{
  padding:16px 12px;
  align-items: center;
  border-radius: 16px;
  color:#000000;
  background-color: #F6F6F6;
}
.white_in_btn{
  padding:15px 24px;
  align-items: center;
  color:#1A1E21;
  background-color: #FFFFFF;
}

.purple_in_btn{
  padding:15px 24px;
  align-items: center;
  color:#FFFFFF;
  background-color: #4C31B0;
}

.blue_in_btn{
  padding:15px 24px;
  align-items: center;
  color:#FFFFFF;
  background-color: #317FF4;
}
.blue01_in_btn{
  padding:15px 24px;
  align-items: center;
  color:#FFFFFF;
  background-color: #030e1f;
}
.gray_in_btn{
  padding:15px 24px;
  align-items: center;
  color:#FFFFFF;
  background-color: #828282;
}
.gray_in_btn3{
  padding:15px 24px;
  align-items: center;
  color:#5B5B5B;
  background-color: #F6F6F6;
}

.black_in_btn{
  padding:15px 24px;
  align-items: center;
  color:#FFFFFF;
  background-color: #000000;
}
.yellow_in_btn{
  padding:15px 24px;
  align-items: center;
  color:#1A1E21;
  background-color: #FFDD00;
}

.layer_box{
  border-radius:24px;
  border:1px solid #DADADA;
  padding:24px;
}
.inputText_None {
  width: 100%;
  font-size: 22px;
  font-width: 700;
  text-align: right;
  border: 0;
  background-color:#F8F8F8;
}

.inputText_None:focus {
  outline: none;
  margin-left: 0;
  border: 0;
  border-bottom: solid 1px #FFDD00;
}

.inputText_None::placeholder {
  color: #dbdee6;
  font-size: 14px;
}
.inputText_Bottom {
  height: 44px;
  width: 100%;
  font-size: 14px;
  text-align: left;
  border: 0;
  background-color:#F8F8F8;
  border-bottom: solid 1px #0F8CFF;
}
.inputText_Bottom:focus {
  outline: none;
  margin-left: 0;
  border: 0;
  border-bottom: solid 1px #FFDD00;
}

.inputText_Bottom::placeholder {
  color: #dbdee6;
  font-size: 14px;
}


.inputText_Bottom_Stroke {
  height: 44px;
  width: 100%;
  color: #dbdee6;
  font-size: 14px;
  text-align: left;
  border: 0;
  border-bottom: solid 1px #f2f4f8;
  margin-left: -3px;
}
.inputText_Bottom_Stroke:focus {
  outline: none;
  margin-left: 0;
  border: 0;
  border-bottom: solid 1px #3a71ff;
}

.inputText_Bottom_Stroke::placeholder {
  color: #dbdee6;
  font-size: 16px;
}




.inputText_44 {
  height: 44px;
  width: 100%;
  font-size: 14px;
  text-align: left;
  border: 0;
  padding-left:20px;
  background-color: #FFFFFF;
  border: solid 1px #D1D5DB;
  border-radius: 16px;
}
.inputText_44:focus {
  outline: none;
  margin-left: 0;
  border: 0;
  border: solid 1px #FFDD00;
}

.inputText_44::placeholder {
  color: #dbdee6;
  font-size: 14px;
}


.inputText_44_Stroke {
  height: 44px;
  width: 100%;
  color: #dbdee6;
  font-size: 14px;
  text-align: left;
  border: 0;
  border-bottom: solid 1px #f2f4f8;
  margin-left: -3px;
}
.inputText_44_Stroke:focus {
  outline: none;
  margin-left: 0;
  border: 0;
  border-bottom: solid 1px #3a71ff;
}

.inputText_44_Stroke::placeholder {
  color: #dbdee6;
  font-size: 16px;
}

.slider_bg{
  background-image: url("../../assets/images/home/main_banner_bg2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.speech_bubble_bg {
  width:92px;
  height: 27px;
  background-image: url("../../assets/images/home/speech_bubble.png");
  background-repeat: no-repeat;
  background-size: cover;
  font-size:12px;
  color: #4C31B0;
  text-align: center;
  display : flex;
  justify-content : center;
  align-items : center;

}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  height: 100vh;
  background-color: #F8F8F8;
  text-align: left;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 1s ease-in-out;
  transform: translateX(100%);
  animation: smoothAppear 0.3s;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  z-index: 100;
}

.overlay[data-open='true'] {
  display: block;
}

.menu[data-open='true'] {
  transform: translateX(0);
}


@mixin topTab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  font-size: 16px;

}

.topTabDefault {
  @include topTab;
  color: #8D94A0;
  font-weight: 500;
  border-bottom: 1px solid #D2D6DB;
}

.topTabActive {
  @include topTab;
  color: #060A10;
  font-weight: 700;
  border-bottom: 2px solid #060A10;
}
.bigSelect {
  position: relative;
  width:100%;
  display: inline-block;
  padding: 10px 10px 10px 20px;
  border-radius: 16px;
  background-color: #FFFFFF;
  border: 1px solid #DADADA;
}
.bigSelectContent {
  display: none;
  position: absolute;
  border-radius: 16px;
  background: #F8F8F8;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.bigSelectContent a {
  color: #000000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}


.bigSelectContent a:hover {background-color: #9b9b9B}


.dropdown {
  position: relative;
  display: inline-block;
  padding: 8px 16px 8px 12px;
  border-radius: 20px;
  background-color: #F8F8F8;
  border: 1px solid #DADADA;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
  display: none;
  position: absolute;
  border-radius: 16px;
  background: #F8F8F8;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdownContent a {
  color: #000000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdownContent a:hover {background-color: #9b9b9B}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdownContent {
  display: block;
}

.txt10_700_9B9B9B{
  font-size: 10px;
  font-weight: 700;
  color:#9B9B9B;
}

.txt12_700{
  font-size: 12px;
  font-weight: 700;
}
.txt12_500{
  font-size: 12px;
  font-weight: 500;
}

.txt12_500_6F7275{
  font-size: 12px;
  font-weight: 500;
  color:#6F7275;
}
.txt12_6F7275{
  font-size: 12px;
  color:#6F7275;
}
.txt12_828282{
  font-size: 12px;
  color:#828282;
}
.txt12_8D94A0{
  font-size: 12px;
  color:#8D94A0;
}
.txt12_8F7AE1{
  font-size: 12px;
  color:#8F7AE1;
}
.txt13_red{
  font-size: 13px;
  color:red;
}
.txt13_700{
  font-size: 13px;
  font-weight: 700;
}
.txt13_8D94A0{
  font-size: 13px;
  color:#8D94A0;
}
.txt13_BDBDBD{
  font-size: 13px;
  color:#BDBDBD;
}
.txt13_696969{
  font-size: 13px;
  color:#696969;
}
.txt14_300{
  font-size: 14px;
  font-weight: 300;
}
.txt14{
  font-size: 14px;
  font-weight: 400;
}
.txt14_500{
  font-size: 14px;
  font-weight: 500;
}
.txt14_500_W{
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
}
.txt14_500_9B9B9B{
  font-size: 14px;
  font-weight: 500;
  color:#9B9B9B;
}
.txt14_500_828282{
  font-size: 14px;
  font-weight: 500;
  color:#828282;
}
.txt14_500_374151{
  font-size: 14px;
  font-weight: 500;
  color:#374151;
}
.txt14_500_3A71FF{
  font-size: 14px;
  font-weight: 500;
  color:#3A71FF;
}
.txt14_500_red{
  font-size: 14px;
  font-weight: 500;
  color: #B30A0AFF;
}
.txt14_500_green{
  font-size: 14px;
  font-weight: 500;
  color: #008802FF;
}
.txt14_700{
  font-size: 14px;
  font-weight: 700;
}
.txt14_700_9B9B9B{
  font-size: 14px;
  ont-weight: 700;
  color:#9B9B9B;
}
.txt14_700_424242{
  font-size: 14px;
  ont-weight: 700;
  color:#424242;
}
.txt14_9B9B9B{
  font-size: 14px;
  color:#9B9B9B;
}
.txt14_0F8CFF{
  font-size: 14px;
  color:#0F8CFF;
}
.txt14_828282{
  font-size: 14px;
  color:#828282;
}
.txt14_696969{
  font-size: 14px;
  color:#696969;
}
.txt15_696969{
  font-size: 15px;
  color: #2c2c2c;
}
.txt15_500{
  font-size: 15px;
  font-weight: 500;
}
.txt13_W{
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFF;
}
.txt14_W{
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}
.txt15_W{
  font-size: 15px;
  font-weight: 400;
  color: #FFFFFF;
}
.txt15_700_W{
  font-size: 15px;
  font-weight: 700;
  color: #FFFFFF;
}
.txt16_400{
  font-size: 16px;
  font-weight: 400;
}
.txt16_500{
  font-size: 16px;
  font-weight: 500;
}
.txt16_500_828282{
  font-size: 16px;
  font-weight: 500;
  color:#828282;
}
.txt16_500_374151{
  font-size: 16px;
  font-weight: 500;
  color:#374151;
}
.txt16_500_808387{
  font-size: 16px;
  font-weight: 500;
  color:#808387;
}
.txt16_500_EB4E46{
  font-size: 16px;
  font-weight: 500;
  color:#EB4E46;
}
.txt16_400_5B5B5B{
  font-size: 16px;
  color:#5B5B5B;
}
.txt16_500_0F8CFF{
  font-size: 16px;
  font-weight: 500;
  color:#0F8CFF;
}

.txt16_700{
  font-size: 16px;
  font-weight: 700;
}
.txt16_700_5B5B5B{
  font-size: 16px;
  font-weight: 700;
  color:#5B5B5B;
}
.txt18_500{
  font-size: 18px;
  font-weight: 500;
}
.txt18_700{
  font-size: 18px;
  font-weight: 700;
}
.txt18_700_9B9B9B{
  font-size: 18px;
  font-weight: 700;
  color:#9B9B9B;
}
.txt18_700_374151{
  font-size: 18px;
  font-weight: 700;
  color:#374151;
}
.txt19_700_424242{
  font-size: 19px;
  font-weight: 700;
  color:#424242;
}
.txt20_400{
  font-size: 20px;
  font-weight: 400;
}
.txt20_700{
  font-size: 20px;
  font-weight: 700;
}
.txt22_700{
  font-size: 22px;
  font-weight: 700;
}
.txt24_400_323A40{
  font-size: 24px;
  font-weight: 400;
  color:#323A40;
}

.txt24_400_828282{
  font-size: 24px;
  font-weight: 400;
  color:#828282;
}
.txt24_700{
  font-size: 24px;
  font-weight: 700;
}
.txt20_500{
  font-size: 20px;
  font-weight: 500;
}
.txt20_500_9B9B9B{
  font-size: 20px;
  font-weight: 500;
  color:#9B9B9B;
}
.txt20_500_060A10{
  font-size: 20px;
  font-weight: 500;
  color:#060A10;
}
.txt26_700 {
  font-size: 26px;
  font-weight: 700;
}
.txt28_700 {
  font-size: 28px;
  font-weight: 700;
}
.txt30_700{
  font-size: 30px;
  font-weight: 700;
}

.txt30_700_828282{
  font-size: 30px;
  font-weight: 700;
  color:#828282;
}

.txt30_900_FFDD00{
  font-size: 30px;
  font-weight: 900;
  color:#FFDD00;
}

.txt34_700{
  font-size: 34px;
  font-weight: 700;
}
.txt36_700{
  font-size: 36px;
  font-weight: 700;
}
.txt38_700{
  font-size: 38px;
  font-weight: 700;
}
.txt40_700{
  font-size: 40px;
  font-weight: 700;
}